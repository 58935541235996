import React from 'react'

function Hamburgur() {
    return (
        <div className='svg-dashboard'>
            <svg width="38" height="30" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="2" y1="2" x2="36" y2="2" stroke="white" strokeWidth="4" strokeLinecap="round" />
                <line x1="2" y1="14.5505" x2="25.1428" y2="14.5505" stroke="white" strokeWidth="4" strokeLinecap="round" />
                <line x1="2" y1="27.1009" x2="30.5714" y2="27.1009" stroke="white" strokeWidth="4" strokeLinecap="round" />
            </svg>
        </div>
    )
}

export default Hamburgur