import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, memo } from 'react';

import Logo from '../assets/images/logo-icon.png'
import Dashboard from "./shared/svgs/Dashboard";
import Coffee from "./shared/svgs/Coffee";
import Users from "./shared/svgs/Users";
import { CiShop } from "react-icons/ci";


function SideBar({ props, hamburgerActive, SetHamburgurActive }) {
    const location = useLocation()
    const [url, setUrl] = useState(location.pathname)
    useEffect(() => {
        setUrl(location.pathname);
    })

    function closeNav() {
        var x = window.matchMedia("(max-width: 768px)")
        if (x.matches) {
            document.getElementById("sidebar").style.width = "0";
        }
        // document.getElementById("sidebar").style.position = "sticky";
    }
    return <>
        <div className="sidebar" id="sidebar">
            <div className="close-nav-section">
                <div className="logo">
                    <img src='/images/logo-red.png' />
                    {/* <h4 className="primary-text">Galaxy</h4> */}
                </div>
                {/* <i className="fa-solid fa-times close-sidebar-icon" ></i> */}
            </div>

            <div className="sidebar-scroll">
                <ul className="sidebar-ul">
                    <li className={url === "/" ? "active" : ''} style={{paddingInline : hamburgerActive ? '20px' : '35px'}}>
                        <Link to={"/"} style={{justifyContent : hamburgerActive ? 'start' : 'center'}}>
                            <Dashboard />
                            {hamburgerActive &&
                                <div><span>Dashboard</span></div>
                            }
                        </Link>
                    </li>
                    <li className={url === "/coffe-recipe" ? "active" : ''} style={{paddingInline : hamburgerActive ? '20px' : '35px'}}>
                        <Link to={"/coffe-recipe"}  style={{justifyContent : hamburgerActive ? 'start' : 'center'}}>
                            <Coffee />
                            {hamburgerActive &&
                                <div><span>Coffee Recipe</span></div>
                            }
                            </Link>
                    </li>
                    {/* <li className={url === "/users" ? "active" : ''} style={{paddingInline : hamburgerActive ? '20px' : '35px'}}>
                        <Link to={"/users"}><Users style={{justifyContent : hamburgerActive ? 'start' : 'center'}}/>
                        {hamburgerActive &&
                                <div><span>Users</span></div>
                            }
                        </Link>
                    </li> */}
                    <li className={url === "/merchandise" ? "active" : ''} style={{paddingInline : hamburgerActive ? '20px' : '35px'}}>
                        <Link to={"/merchandise"}><CiShop  style={{justifyContent : hamburgerActive ? 'start' : 'center'}} fontSize={45}/>
                        {hamburgerActive &&
                                <div><span>Merchandise</span></div>
                            }
                        </Link>
                    </li>

                </ul>
            </div>
        </div>
    </>
}
export default memo(SideBar);