import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Table,
  Container,
  Form,
  Modal as CustomModal,
  Row,
} from "react-bootstrap";
import apis from "../services";
import { useQuery } from "@tanstack/react-query";

const CokeModal = ({
  show,
  handleClose,
  modalTitle,
  handleSubmit,
  data,
  setData,
  loader,
  reqireState,
  id,
  recipeStepId,
}) => {
  useEffect(() => {
    if (modalTitle === "Create Cook") {
      setData({ description: "", no: "", recipeId: id });
    }
  }, [modalTitle]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <CustomModal
      show={show}
      onHide={handleClose}
      centered
      className="custom-modal"
    >
      <CustomModal.Header>
        <CustomModal.Title className="text-color">
          {modalTitle} Step
        </CustomModal.Title>
      </CustomModal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <CustomModal.Body>
          <div className="dashboard-box  reward-token-box ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-color">Cook Quantity</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                name="no"
                className="custom-input"
                placeholder="Quantity in numbers or kgs/grams"
                value={data?.no}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-color">Cook Description English</Form.Label>
              <Form.Control
                as="textarea"
                name="description_en"
                className="custom-input"
                placeholder="Description English"
                value={data?.description}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-color">Cook Description Spanish</Form.Label>
              <Form.Control
                as="textarea"
                name="description_sp"
                className="custom-input"
                placeholder="Description Spanish"
                value={data?.description}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>
          </div>
        </CustomModal.Body>
        <CustomModal.Footer>
          {/* <button className='custom-btn secondary-btn round-btn' style={{backgroundColor:'white' ,color:'black', border:'none'}} onClick={handleClose}>
                    Close
                </button> */}

          <button
            className="custom-btn primary-btn round-btn"
            type="submit"
            disabled={loader}
          >
            {loader ? "Loading" : `${modalTitle} Step`}
          </button>
        </CustomModal.Footer>
      </Form>
    </CustomModal>
  );
};

export default CokeModal;
