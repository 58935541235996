import { Box } from "@mui/material";
import toast from "react-hot-toast";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AddRecipeForm from "../components/forms/AddRecipeForm";
import apis from "../services";

const INITIAL_FORM_STATE = {
  image: "",
  name_en: "",
  name_sp: "",
  ratio: "",
  description_en: "",
  description_sp: "",
  grind_size: "Coarse",
  gram: { water: "", coffe: "" },
  oZ: { water: "", coffe: "" },
};

function AddUpdateRecipe() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [RecipeData, setRecipeData] = useState(INITIAL_FORM_STATE);
  const { id: recipeId } = useParams();
  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    const { name, value, files } = e.target;
    console.log(e.target, "e.targete.targete.targete.target");
    if (files) {
      setRecipeData((prevState) => ({ ...prevState, image: files[0] }));
      return;
    }
    if (["water", "coffe"].includes(name)) {
      setRecipeData((prevState) => ({
        ...prevState,
        oZ: { ...prevState.oZ, [name]: value },
      }));
    } else if (["water1", "coffe1"].includes(name)) {
      if (name === "water1") {
        setRecipeData((prevState) => ({
          ...prevState,
          gram: { ...prevState.gram, water: value },
        }));
      } else if (name === "coffe1") {
        setRecipeData((prevState) => ({
          ...prevState,
          gram: { ...prevState.gram, coffe: value },
        }));
      }
    } else {
      setRecipeData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const fetchRecipeDetails = async (recipeId) => {
    try {
      const res = await apis.getOneRecipe(recipeId);
      console.log(res);
      if (res.data.status) {
        setRecipeData(res.data.recipe);
      }
    } catch (e) {
      console.error("ERROR in fetching Recipe details", e);
    }
  };

  const onCreateRecipeHandler = async (data) => {
    try {
      const form_data = new FormData();
      for (const [key, value] of Object.entries(data)) {
        console.log(key, "keykeykeykey");

        if (typeof value === "object" && key !== "image") {
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            form_data.append(`${key}[${nestedKey}]`, nestedValue);
          }
        } else {
          console.log(key, "  ", value, "valuevaluevalue");
          form_data.append(key, value);
        }
      }

      const res = await apis.createRecipe(form_data);
      if (res?.data?.status) {
        toast.success(res.data.message);

        setRecipeData(INITIAL_FORM_STATE);
        navigate("/coffe-recipe");
      }
    } catch (e) {
      console.error("ERROR on Create Recipe", e);
    }
  };
  const onUpdateRecipeHandler = async (data) => {
    try {
      const form_data = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === "object" && key !== "image") {
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            form_data.append(`${key}[${nestedKey}]`, nestedValue);
          }
        } else {
          form_data.append(key, value);
        }
      }
      console.log("formDAAATTA", form_data);
      console.log("RecipeDataRecipeData", RecipeData);

      const res = await apis.updateRecipe(recipeId, form_data);
      console.log("RESPONSE UPDATDE", res);
      if (res?.data?.status) {
        toast.success(res.data.message);
        navigate("/coffe-recipe");
        setRecipeData("");
      }
    } catch (e) {
      console.error("ERROR on update Recipe", e);
    }
  };

  useEffect(() => {
    (async () => {
      if (recipeId) await fetchRecipeDetails(recipeId);
    })();
  }, [recipeId]);

  const onSubmitHandler = async (data) => {
    // console.log(RecipeData,"funtioncalll")
    if (recipeId) await onUpdateRecipeHandler(RecipeData);
    else await onCreateRecipeHandler(RecipeData);
  };

  return (
    <>
      <Box className="dashboard-main">
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              // height: "100vh",
              // overflow: "auto",
            }}
            className="custom-scroll"
          >
            <div className="container-fluid p-0">
              <h3 className="section-title dark-text text-start mb-0">
                {recipeId ? "Update" : "Add"} Recipe
              </h3>
              <AddRecipeForm
                formData={RecipeData}
                onChangeHandler={onChangeHandler}
                submitHandler={onSubmitHandler}
                heading={recipeId ? "Update" : "Add"}
                recipeId={recipeId}
                setRecipeData={setRecipeData}
              />
            </div>
          </Box>
        </div>
      </Box>
    </>
  );
}

export default AddUpdateRecipe;
