import axios from "axios";
const createBackendServer = (baseURL) => {
  
  axios.defaults.withCredentials = true;
  const api = axios.create({
    baseURL: `${baseURL}/api`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": '*'
  },
    timeout: 60 * 1000,
  });
  
  const headers = {
    "Content-Type": "multipart/form-data"
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
      const message = error.response.data;
      error.message = message || error.message
      /*if(error?.response?.data?.errors)
          error.errors = error?.response?.data?.errors;*/
      return Promise.reject(error)
  })

  /***********    GET REQUESTS    **********/
  const getAllUsers = () => api.get(`/user`);

  const getAllRecipe = () => api.get(`/recipe-admin`);
  
  const getRecipeStep = (id) => api.get(`/recipeStep/recipe/${id}`);

  const getCookStep = (id) => api.get(`/cookStep/recipe/${id}`);

  const getOneRecipeStep = (id) => api.get(`/recipeStep/${id}`);

  const getOneCookStep = (id) => api.get(`/cookStep/${id}`);

  const getOneRecipe = (id) => api.get(`/recipe/${id}`);

  const getAnalytics = () => api.get(`/auth/analytics`);


  const getChartAnalytics = (period) => api.get(`/recipeHistoryAnalytics?period=${period}`);

   const logout = () => api.get('/auth/logout');
  
   
  /***********    POST REQUESTS    **********/
  
  const createRecipe = (body) => api.post(`/recipe`,body);
  
  const createRecipeStep = (body) => api.post(`/recipeStep`,body);
  
  const createCookStep = (body) => api.post(`/cookStep`,body);
  
  const login = async (body) => api.post("/auth/admin", body);


  /***********    PUT REQUESTS    **********/
  
  const updateRecipe = (id,body) => api.put(`/recipe/update/${id}`,body);
  const updateRecipeStep = ({recipeStepId,form_data}) => api.put(`/recipeStep/update/${recipeStepId}`,form_data);
  const updateCookStep = ({cookStepId,form_data}) => api.put(`/cookStep/update/${cookStepId}`,form_data);
  


  /***********    Delete REQUESTS    **********/

  const   deleteRecipe = (id) => api.delete(`/recipe/${id}`);
  const   deleteRecipeStep = (id) => api.delete(`/recipeStep/${id}`);
  const   deleteCookStep = (id) => api.delete(`/cookStep/${id}`);
  
  const   deleteUser = (id) => api.delete(`/user/${id}`);
  
  return {
    getChartAnalytics,
    deleteUser,
    getAllUsers,
    deleteRecipeStep,
    getOneRecipeStep,
    createRecipeStep,
    updateRecipe,
    getOneRecipe,
    getAnalytics,
    login,
    logout,
    getAllRecipe,
    getRecipeStep,
    createRecipe,
    deleteRecipe,
    updateRecipeStep,
    createCookStep,
    updateCookStep,
    deleteCookStep,
    getCookStep,
    getOneCookStep
  };
};


const apis = createBackendServer(process.env.REACT_APP_URL);


export default apis;
