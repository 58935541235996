import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import Modal from "../components/Modal";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { Grid, IconButton, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Delete,
  RemoveModerator,
  Send,
  VerifiedUser,
  Visibility,
  MonetizationOn,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import DeleteModal from "../components/DeleteModal";
import CokeModal from "../components/CokeModal";

const CokeSteps = () => {
  const [show, setShow] = useState(false);
  const [Recipes, setRecipes] = useState([]);
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const [reqireState, SetreqireState] = useState(true);
  const [isFetching, setFetching] = useState(false);

  const { id } = useParams();

  const [showDelete, setShowDelete] = useState(false);

  const handleShowDelete = (id) => {
    setShowDelete(true);
    setCookStepId(id);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
    setCookStepId("");
  };

  const [modalData, setModalData] = useState({
    description_en: "",
    description_sp: "",
    no: "",
    recipeId: id,
  });

  const [values, setValues] = useState([]);

  const [cookStepId, setCookStepId] = useState("");

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    apis.deleteCookStep,
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: ({ data }) => {
        if (data.status) {
          refetch();
          console.log(data);
          toast.success(data.message, { id: 1 });
          handleCloseDelete();
        }
      },
    }
  );

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(
    apis.updateCookStep,
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: ({ data }) => {
        if (data.status) {
          refetch();
          toast.success(data.message, { id: 1 });
          setShow(false);
          SetreqireState(true);
          setCook("");
        }
      },
    }
  );
  const { mutate: mutateAddCookStep, isLoading: isLoadingAdd } = useMutation(
    apis.createCookStep,
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: ({ data }) => {
        if (data.status) {
          refetch();
          toast.success(data.message, { id: 1 });
          setShow(false);
          SetreqireState(true);
          setModalData("");
        }
      },
    }
  );

  console.log(values, "VALUES");
  const handleShow = (Recipe, title, cookStepId, index) => {
    if (title === "Edit Coke") {
      SetreqireState(false);
      setCookStepId(cookStepId);
      const { __v, createdAt, updatedAt, _id, ...rest } = Recipe[index];
      setModalData(rest);
    }

    setTitle(title);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCookStepId("");
  };
  const handleSubmit = async (cooks) => {
    if (title === "Edit Coke") {
      mutateEdit({ cookStepId, form_data: cooks });
    } else {
      mutateAddCookStep(cooks);
    }
  };

  const handleDelete = async (id) => {
    mutateDelete(id);
  };

  const navigate = useNavigate();

  const [cook, setCook] = useState({});

  const { id: _id } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ["getCokeSteps"],
    () => apis.getCookStep(id),
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: (data) => {
        let cokeData = data?.data?.cookStep;
        // console.log("cokeDatacokeData", data);
        setCook(cokeData);
      },
    }
  );

  if (isLoading)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );

  console.log("COOKKK", cook);
  const renderRecipesList =
    cook?.length > 0 &&
    cook.map((item, index) => {
      return (
        <tbody key={index}>
          <tr>
            <td scope="row">{index + 1}</td>

            <td>{item?.no}</td>
            <td className="line-clamp">{item?.description_en}</td>
            <td className="line-clamp">{item?.description_sp}</td>

            <td>
              <Grid container>
                <Grid item>
                  <Tooltip title="View And Edit" arrow>
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleShow(cook, "Edit Coke", item?._id, index)
                      }
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      onClick={() => handleShowDelete(item?._id)}
                      size="small"
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </td>
          </tr>
        </tbody>
      );
    });

  return (
    <>
      <Container fluid className="main-height">
        <Row>
          <Col lg={12} md={12} className="p-0">
            <div className="custom-chart-margin">
              <div className="d-flex justify-content-between">
                <h5 className="section-title">Cook Steps</h5>
                <button
                  className="lp-btn"
                  onClick={() => handleShow(null, "Create Coke")}
                  style={{ width: "220px" }}
                >
                  Add Cook Steps
                </button>
              </div>

              <div className="overflow-auto user-table coke-steps">
                <table className="table table-striped mt-3">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Description English</th>
                      <th scope="col">Description Spanish</th>

                      <th width="150px" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {renderRecipesList}
                </table>
              </div>
            </div>
          </Col>
        </Row>
        <CokeModal
          modalTitle={title}
          show={show}
          data={modalData}
          setData={setModalData}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          loader={isLoadingAdd || isLoadingEdit}
          reqireState={reqireState}
          id={_id}
          cookStepId={cookStepId}
        />

        <DeleteModal
          show={showDelete}
          handleClose={handleCloseDelete}
          handleSubmit={handleDelete}
          loader={isLoadingDelete}
          id={cookStepId}
          title={"Delete Recipe Step"}
        />
      </Container>
    </>
  );
};
export default memo(CokeSteps);
