import React from 'react'

function Users() {
    return (
        <div className='svg-users'>
            <svg width="51" height="43" viewBox="0 0 51 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.1287 42.5765H15.5347C13.8606 42.5765 13.4299 42.1556 13.4299 40.4913C13.4299 35.9782 13.4299 31.4749 13.4299 26.9618C13.4299 22.2333 16.4843 19.2083 21.2617 19.1887H29.0936C33.7633 19.1887 36.8079 22.2431 36.8177 26.9324C36.8177 31.4358 36.8177 35.9489 36.8177 40.4522C36.8177 42.1654 36.3968 42.5765 34.6444 42.5765H25.1287Z" fill="#3E3E3E" />
                <path d="M33.7928 8.77236C33.788 9.92389 33.5534 11.0629 33.1027 12.1226C32.6521 13.1823 31.9945 14.1414 31.1684 14.9437C30.3424 15.7461 29.3646 16.3755 28.2922 16.7951C27.2198 17.2147 26.0745 17.4161 24.9233 17.3874C23.7796 17.3658 22.6514 17.118 21.6039 16.6582C20.5565 16.1984 19.6104 15.5357 18.8204 14.7084C18.0304 13.881 17.4121 12.9054 17.001 11.8379C16.59 10.7703 16.3945 9.63197 16.4257 8.48846C16.4822 6.19902 17.4388 4.0241 19.0882 2.43524C20.7375 0.846392 22.9466 -0.0283334 25.2365 0.000700331C27.5328 0.0342371 29.7222 0.975998 31.3258 2.61991C32.9293 4.26382 33.8164 6.476 33.7928 8.77236Z" fill="#3E3E3E" />
                <path d="M10.9042 23.0655C10.1993 29.5757 10.728 36.037 10.5517 42.5668C10.344 42.5969 10.1349 42.6165 9.92522 42.6255C7.90852 42.6255 5.87224 42.6745 3.86533 42.5276C2.84011 42.4539 1.8802 41.997 1.17642 41.2479C0.472635 40.4988 0.0765255 39.5122 0.0668779 38.4844C-0.00165074 35.5475 -0.0408099 32.6693 0.0668779 29.7617C0.167932 28.0163 0.908756 26.3694 2.14772 25.1358C3.38668 23.9023 5.03686 23.1687 6.7827 23.0753C8.19243 22.9676 9.58257 23.0655 10.9042 23.0655Z" fill="#3E3E3E" />
                <path d="M39.6667 42.5276C39.5101 35.988 39.9898 29.5365 39.3339 22.948C41.1516 22.9413 42.9674 23.0656 44.7672 23.32C46.2947 23.6704 47.6592 24.5261 48.6399 25.7484C49.6206 26.9708 50.1601 28.4883 50.1712 30.0554C50.2201 32.7672 50.1712 35.479 50.1712 38.1907C50.1948 38.7406 50.1096 39.2897 49.9205 39.8066C49.7313 40.3234 49.442 40.7978 49.0691 41.2026C48.6962 41.6074 48.2469 41.9345 47.7473 42.1653C47.2476 42.396 46.7073 42.5258 46.1574 42.5472C43.9938 42.6451 41.8107 42.5276 39.6667 42.5276Z" fill="#3E3E3E" />
                <path d="M9.8175 21.1075C8.95914 21.1129 8.10837 20.9466 7.31517 20.6185C6.52197 20.2904 5.80236 19.8071 5.1986 19.1969C4.59483 18.5868 4.11909 17.8621 3.79935 17.0655C3.4796 16.2689 3.32229 15.4164 3.33666 14.5581C3.33922 13.7084 3.50915 12.8674 3.83672 12.0833C4.1643 11.2991 4.64312 10.5872 5.24583 9.98815C5.84854 9.38907 6.56335 8.91458 7.34944 8.59175C8.13553 8.26893 8.97751 8.1041 9.8273 8.10667C11.5427 8.10406 13.1893 8.78169 14.4059 9.99102C15.6226 11.2003 16.3102 12.8427 16.3179 14.5581C16.3257 15.4163 16.1632 16.2674 15.8399 17.0623C15.5166 17.8572 15.0388 18.5802 14.4343 19.1892C13.8298 19.7983 13.1105 20.2815 12.318 20.6107C11.5256 20.94 10.6757 21.1089 9.8175 21.1075Z" fill="#3E3E3E" />
                <path d="M46.8916 14.6462C46.8943 15.5047 46.7259 16.3551 46.3962 17.1477C46.0666 17.9404 45.5823 18.6594 44.9716 19.2627C44.361 19.8661 43.6362 20.3417 42.8396 20.6618C42.0431 20.9819 41.1907 21.1401 40.3324 21.1271C38.617 21.1116 36.9777 20.4166 35.7738 19.1944C34.57 17.9722 33.8998 16.3226 33.9103 14.6071C33.9089 13.749 34.0782 12.8992 34.4083 12.1072C34.7384 11.3152 35.2228 10.5967 35.8332 9.99358C36.4436 9.39048 37.1678 8.91479 37.9637 8.59421C38.7597 8.27363 39.6115 8.11456 40.4695 8.12624C41.3193 8.13265 42.1595 8.30637 42.9421 8.6375C43.7248 8.96863 44.4345 9.45068 45.0309 10.0561C45.6272 10.6616 46.0985 11.3785 46.4178 12.1661C46.737 12.9537 46.898 13.7964 46.8916 14.6462Z" fill="#3E3E3E" />
            </svg>
        </div>
    )
}

export default Users