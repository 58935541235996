import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Table,
  Container,
  Form,
  Modal as CustomModal,
  Row,
  CloseButton,
} from "react-bootstrap";
import apis from "../services";
import { useQuery } from "@tanstack/react-query";
import { ImCross } from "react-icons/im";
import { useParams } from "react-router-dom";

const Modal = ({
  show,
  handleClose,
  modalTitle,
  handleSubmit,
  data,
  setData,
  loader,
  recipeStepId,
  INITIAL_FORM_STATE
}) => {
  
  const { id } = useParams();
  let reqireState =  modalTitle === "Create Recipe" ? true : false; 
  // const { data:reciepeData, isLoading , refetch } = useQuery(
  //     ["getOneRecipeStep"],
  //     () => apis.getOneRecipeStep(recipeStepId,{
  //         enabled:false
  //     }),
  //     {
  //       onError: function ({ message }) {
  //         console.log(message);
  //       },
  //       onSuccess: (reciepeData) => {
  //         let recipeData = reciepeData?.data?.recipeStep;

  //         setData(recipeData);
  //       },
  //     }
  //   );

  //   useEffect(() => {
  //     if(recipeStepId){
  //         refetch();
  //     }
  // },[recipeStepId]);


 

  useEffect(() => {
    if (modalTitle === "Create Recipe") {
      // setData(INITIAL_FORM_STATE);
    }
  }, [modalTitle,id]);

  const onChangeHandler = (e) => {
    const { name, value, files } = e.target;
    console.log(e.target.value, "e.targete.targete.targete.target");

    if (files) {
      setData((prevData) => ({ ...prevData, [name]: files[0] }));
    } else if (["water", "coffe"].includes(name)) {
      setData((prevState) => ({
        ...prevState,
        oZ: { ...prevState.oZ, [name]: value },
      }));
    } else if (["water1", "coffe1"].includes(name)) {
      if (name === "water1") {
        setData((prevState) => ({
          ...prevState,
          gram: { ...prevState.gram, water: value },
        }));
      } else if (name === "coffe1") {
        setData((prevState) => ({
          ...prevState,
          gram: { ...prevState.gram, coffe: value },
        }));
      }
    } else {
      setData((prevState) => ({ ...prevState, [name]: value }));
    }

    //

    // else {
    //   setData((prevData) => ({ ...prevData, [name]: value }));
    // }
  };
  let TYPE = ["make", "wait" , "stir","bloom"];

  console.log("recipe step data", data?.type);
  return (
    <CustomModal
      show={show}
      onHide={handleClose}
      centered
      className="custom-modal"
    >
      <CustomModal.Header>
        <CustomModal.Title className="text-color">
          {modalTitle} Step
        </CustomModal.Title>
        <ImCross
          onClick={handleClose}
          color="#fff"
          style={{ cursor: "pointer" }}
        />
        {/* <CloseButton onClick={handleClose} aria-label="Close" /> */}
      </CustomModal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(data);
        }}
      >
        <CustomModal.Body>
          <div className="dashboard-box  reward-token-box ">
            <Form.Group className="mb-3" controlId="formFileSm">
              <Form.Label className="text-color">Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/png, image/gif, image/jpeg"
                size="sm"
                className="custom-input"
                name="image"
                onChange={onChangeHandler}
                required={reqireState}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-color">Recipe Step Number</Form.Label>
              <Form.Control
                type="number"
                name="no"
                className="custom-input"
                placeholder="Step No"
                value={data?.no}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-color">
                Recipe Step Title English
              </Form.Label>
              <Form.Control
                type="text"
                name="title_en"
                className="custom-input"
                placeholder="Step Title"
                value={data?.title_en}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-color">
                Recipe Step Title Spanish
              </Form.Label>
              <Form.Control
                type="text"
                name="title_sp"
                className="custom-input"
                placeholder="Step Title"
                value={data?.title_sp}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-color">
                Recipe Step Description English
              </Form.Label>
              <Form.Control
                as="textarea"
                name="description_en"
                className="custom-input"
                placeholder="Description"
                value={data?.description_en}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-color">
                Recipe Step Description Spanish
              </Form.Label>
              <Form.Control
                as="textarea"
                name="description_sp"
                className="custom-input"
                placeholder="Description"
                value={data?.description_sp}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-color">
                Recipe Step Duration
              </Form.Label>
              <Form.Control
                type="text"
                className="custom-input"
                name="duration"
                placeholder="Step Duration"
                value={data?.duration}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="state">
              <Form.Label className="text-color">Type</Form.Label>
              <Form.Select
                name="type"
                required
                onChange={onChangeHandler}
                value={data?.type}
                className="custom-input select"
              >
                <option disabled>Select Type...</option>
                {TYPE.map((value) => (
                  <option key={value}>{value}</option>
                ))}
              </Form.Select>
            </Form.Group>
{data?.type == "make" && 
<>
<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="text-color">Oz water</Form.Label>
            <Form.Control
              type="text"
              className="custom-input"
              name="water"
              placeholder="OZ Water"
              value={data?.oZ?.water}
              onChange={onChangeHandler}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="text-color">Oz Coffee</Form.Label>
            <Form.Control
              type="text"
              className="custom-input"
              name="coffe"
              placeholder="OZ Coffee"
              value={data?.oZ?.coffe}
              onChange={onChangeHandler}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="text-color">Gram Water</Form.Label>
            <Form.Control
              type="text"
              className="custom-input"
              name="water1"
              placeholder="Gram Water"
              value={data?.gram?.water}
              onChange={onChangeHandler}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="text-color">Gram Coffee</Form.Label>
            <Form.Control
              type="text"
              className="custom-input"
              name="coffe1"
              placeholder="Gram Coffee"
              value={data?.gram?.coffe}
              onChange={onChangeHandler}
              required
            />
          </Form.Group>
          </>
}
          </div>
        </CustomModal.Body>
        <CustomModal.Footer>
          {/* <button className='custom-btn secondary-btn round-btn' style={{backgroundColor:'white' ,color:'black', border:'none'}} onClick={handleClose}>
                    Close
                </button> */}

          <button
            className="custom-btn primary-btn round-btn"
            type="submit"
            disabled={loader}
          >
            {loader ? "Loading" : `${modalTitle} Step`}
          </button>
        </CustomModal.Footer>
      </Form>
    </CustomModal>
  );
};

export default Modal;
