import "./App.css";
import { SideBar, Header, ProtectedRoute } from "./components/Index";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import CoffeRecipe from "./screens/CoffeRecipe";
import Home from "./screens/Home";
import Users from "./screens/Users";
import Signin from "./screens/Signin";
import CoffeRecipeSteps from "./screens/CoffeRecipeSteps";
import AddUpdateRecipe from "./screens/AddUpdateRecipe";

import { useEffect, useState } from "react";
import CokeSteps from "./screens/CokeSteps";
import Merchandise from "./screens/Merchandise";

function App() {
  const location = useLocation();
  const [hamburgerActive, SetHamburgurActive] = useState(false);

  return (
    <>
      <div className="App main-body">
        {location.pathname === "/sign-in" ? (
          ""
        ) : (
          <SideBar
            hamburgerActive={hamburgerActive}
            SetHamburgurActive={SetHamburgurActive}
          />
        )}
        <div className="main-section">
          {location.pathname === "/sign-in" ? (
            ""
          ) : (
            <Header
              hamburgerActive={hamburgerActive}
              SetHamburgurActive={SetHamburgurActive}
            />
          )}

          <Routes>
            <Route path="/sign-in" element={<Signin />} />

            <Route exact path="/" element={<ProtectedRoute />}>
              <Route
                path="users"
                element={
                  <Users
                    hamburgerActive={hamburgerActive}
                    SetHamburgurActive={SetHamburgurActive}
                  />
                }
              />
              <Route
                path="coffe-recipe"
                element={
                  <CoffeRecipe
                    hamburgerActive={hamburgerActive}
                    SetHamburgurActive={SetHamburgurActive}
                  />
                }
              />
              <Route
                path="merchandise"
                element={
                  <Merchandise
                    hamburgerActive={hamburgerActive}
                    SetHamburgurActive={SetHamburgurActive}
                  />
                }
              />

              <Route
                path="add-recipe-steps/:id"
                element={
                  <CoffeRecipeSteps
                    hamburgerActive={hamburgerActive}
                    SetHamburgurActive={SetHamburgurActive}
                  />
                }
              />
              <Route
                path="coke-steps/:id"
                element={
                  <CokeSteps
                    hamburgerActive={hamburgerActive}
                    SetHamburgurActive={SetHamburgurActive}
                  />
                }
              />

              <Route
                path="add-recipe"
                element={
                  <AddUpdateRecipe
                    hamburgerActive={hamburgerActive}
                    SetHamburgurActive={SetHamburgurActive}
                  />
                }
              />

              <Route
                path="edit-recipe/:id"
                element={
                  <AddUpdateRecipe
                    hamburgerActive={hamburgerActive}
                    SetHamburgurActive={SetHamburgurActive}
                  />
                }
              />

              <Route
                path="/"
                element={
                  <Home
                    hamburgerActive={hamburgerActive}
                    SetHamburgurActive={SetHamburgurActive}
                  />
                }
              />
            </Route>
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
