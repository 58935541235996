import { memo, useEffect, useState } from "react";
import { Container } from "react-bootstrap";

const Merchandise = () => {
  return (
    <div className="main-height">
      <div className="merchandise-wrapper">
        <h5 className="section-title">Merchandise</h5>

        <div className="button-wrap">
          <button>MENU</button>
          <button>SHOP</button>
        </div>
      </div>
    </div>
  );
};

export default memo(Merchandise);
