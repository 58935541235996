import React from 'react'

function Cross() {
    return (
        <div className='svg-cross'>
            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13.5" cy="13.5" r="13.5" fill="#2E2E2E" />
                <path d="M13.6346 14.8028L13.3945 15.03C11.7765 16.648 10.1586 18.2677 8.54061 19.8891C8.42943 20.0216 8.28216 20.1189 8.11671 20.1694C7.95126 20.2198 7.77474 20.2211 7.60856 20.1731C7.47795 20.1348 7.3585 20.0656 7.26032 19.9713C7.16215 19.8771 7.08814 19.7605 7.04457 19.6316C7.001 19.5026 6.98914 19.3651 7.01002 19.2306C7.03089 19.0961 7.08388 18.9686 7.16448 18.8589C7.2325 18.7712 7.30764 18.6891 7.38911 18.6137C8.98125 17.0181 10.576 15.4233 12.1733 13.8295C12.2404 13.7624 12.3127 13.703 12.4056 13.6229C12.323 13.5352 12.2611 13.468 12.1965 13.4035C10.5734 11.7786 8.94855 10.1547 7.32198 8.53153C7.20285 8.4311 7.11189 8.30141 7.05803 8.15521C7.00416 8.009 6.98925 7.8513 7.01474 7.69759C7.0413 7.55345 7.1046 7.41861 7.19851 7.30608C7.29242 7.19356 7.41377 7.10716 7.55083 7.05524C7.68789 7.00333 7.83603 6.98765 7.98092 7.00971C8.12582 7.03178 8.26257 7.09085 8.37796 7.18122C8.45854 7.24547 8.53446 7.31536 8.60516 7.39035L13.3893 12.1745C13.4745 12.2724 13.5717 12.3591 13.6785 12.4327C13.7209 12.3409 13.7737 12.2542 13.836 12.1745C15.4471 10.5583 17.059 8.94462 18.6718 7.33355C18.7782 7.20256 18.9197 7.10457 19.0797 7.05108C19.2397 6.99758 19.4117 6.99078 19.5754 7.03147C19.7158 7.06665 19.8448 7.13709 19.9503 7.23611C20.0558 7.33513 20.1342 7.45946 20.1782 7.59729C20.2221 7.73513 20.2302 7.88191 20.2015 8.02372C20.1728 8.16553 20.1084 8.29766 20.0144 8.4076C19.955 8.47989 19.8853 8.54444 19.8181 8.61156L15.034 13.3957C14.9694 13.4603 14.9075 13.53 14.8326 13.61L15.034 13.8217C16.6519 15.4414 18.2708 17.0594 19.8904 18.6756C20.0286 18.7876 20.1295 18.9389 20.1797 19.1095C20.23 19.2801 20.2272 19.4619 20.1719 19.6309C20.1291 19.7625 20.0549 19.8816 19.9557 19.9781C19.8566 20.0746 19.7354 20.1455 19.6027 20.1846C19.47 20.2238 19.3298 20.23 19.1941 20.2028C19.0585 20.1756 18.9315 20.1158 18.8241 20.0285C18.7518 19.9691 18.6873 19.8994 18.6202 19.8323L13.8386 15.0481C13.7714 14.9758 13.7146 14.8984 13.6346 14.8028Z" fill="#9B9B9B" />
            </svg>
        </div>
    )
}

export default Cross